// #region Variables

$color-maven-brand-primary-1: rgba(71,173,226,1.0);
$color-maven-brand-primary-2: rgba(12,119,190,1.0);
$color-maven-brand-primary-3: rgba(43,63,105,1.0);
$color-maven-brand-secondary-1: rgba(254,254,254,1.0);
$color-maven-brand-secondary-2: rgba(87,96,102,1.0);
$color-maven-brand-secondary-3: rgba(0,0,0,1.0);

$color-maven-brand-secondary-1-10: rgba(254,254,254,0.1);
$color-maven-brand-secondary-1-30: rgba(254,254,254,0.3);
$color-maven-brand-secondary-1-70: rgba(254,254,254,0.7);
$color-maven-brand-primary-2-10: rgba(12,119,190,0.1);
$color-maven-brand-primary-2-20: rgba(12,119,190,0.2);
$color-maven-brand-primary-2-80: rgba(12,119,190,0.8);
$color-maven-brand-primary-3-10: rgba(43,63,105,0.1);
$color-maven-brand-primary-3-70: rgba(43,63,105,0.7);
$color-maven-brand-primary-3-80: rgba(43,63,105,0.8);
$color-maven-brand-secondary-2-30: rgba(87, 96, 102, 0.3);
$color-maven-brand-secondary-2-60: rgba(87, 96, 102, 0.6);
$color-maven-brand-secondary-2-80: rgba(87, 96, 102, 0.8);
$color-maven-brand-primary-1-light: lighten($color-maven-brand-primary-1, 30%);
$color-maven-brand-secondary-2-dark: darken($color-maven-brand-secondary-2, 20%);

// $color-maven-bg: #f2f2f2; // matches mavenwe.com
$color-maven-bg: $color-maven-brand-secondary-1;
// $color-maven-fg-primary: rgba(8,27,51,.8); // matches mavenwe.com
$color-maven-fg-primary: $color-maven-brand-secondary-3;

$color-blackish-10: rgba(4, 11, 15, 0.1);
$color-blackish-20: rgba(4, 11, 15, 0.4);
$color-green: #3ec33e;

$site-width: 960px;
$site-padding-sm: 10px;
$site-padding-lg: 50px;

$min-tablet: 768px;
$max-phone: 767px;

// #endregion


// #region AntD Overrides

$primary-color: $color-maven-brand-secondary-2;
$error-color: #ff8da1;

$font-size-base: 16px;
$text-color: $color-maven-brand-secondary-2-dark;
$heading-color: $text-color;
$text-color-secondary: $color-maven-brand-primary-3-70;
$heading-4-size: 22px;

$border-color-base: $color-maven-brand-secondary-2-30;
$border-color-split: $color-maven-brand-primary-2-10;

$btn-height-base: 38px;
$btn-font-size-sm: 14px;
$btn-font-weight: 500;

$layout-body-background: $color-maven-bg;
$layout-header-background: $color-maven-bg;

// #endregion
