@import "theme.scss";

// #region Placeholders 1

%button-reset {
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  -webkit-font-smoothing: inherit;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: auto;
}

// #endregion

// #region General

body {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  margin: 0;
}

button {
  @extend %button-reset;
}

.vis-hidden {
  visibility: hidden;
}

.ReactCollapse--collapse {
  transition: height 400ms;
}

div.container-fluid {
  padding: 0;
}

div.row {
  margin: 0;
}

strong {
  font-weight: 600;
}

p {
  margin: 0;
  padding-bottom: 1em;
}

// For stylized small capital M in mScout.
// Adjust font-weight on a case by case basis to look better when using it.

sub.m {
  bottom: 0;
  text-transform: capitalize;
}

// #endregion

// #region Placeholders 2

%rounded-container {
  background-color: $color-blackish-10;
  border-radius: 10px;
  flex-direction: column;
  margin-top: 30px;
  padding: 16px 20px 18px 20px;
  @media screen and (min-width: $min-tablet) {
    margin-top: 50px;
    padding: 20px 30px 22px 30px;
  }
  %rounded-container__title {
    margin: 0;
  }
  %rounded-container__subtitle {
    margin: 20px 0 0 0;
  }
}

%rounded-container--result-card {
  @extend %rounded-container;
  background-color: $color-maven-brand-primary-2;
  color: $color-maven-brand-secondary-1;
  margin-top: 15px;
  padding-top: 22px;
  padding-bottom: 25px;
  transition: background-color 1s ease;
  @media screen and (min-width: $min-tablet) {
    margin-top: 25px;
  }
  a {
    color: $color-maven-brand-secondary-1-70;
    &:hover {
      color: $color-maven-brand-secondary-1;
    }
  }
  %rounded-container__title--result-card {
    @extend %rounded-container__title;
    align-items: center;
    color: $color-maven-brand-secondary-1;
    display: flex;
    flex-wrap: wrap;
  }
}

%split-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: 375px) {
    align-items: center;
    flex-direction: row;
  }
}

%button-reset {
  -moz-osx-font-smoothing: inherit;
  -webkit-appearance: none;
  -webkit-font-smoothing: inherit;
  background: transparent;
  border: none;
  color: inherit;
  cursor: pointer;
  font: inherit;
  line-height: normal;
  margin: 0;
  overflow: visible;
  padding: 0;
  width: auto;
}

%scroll-link {
  align-items: center;
  color: $color-maven-brand-secondary-1;
  font-weight: 600;
  margin: 4px 0;
  transition: color 0.1s;
  white-space: nowrap;
  &:hover {
    color: $color-maven-brand-secondary-1-70;
  }
  &:focus {
    outline: none;
  }
  &.focus-visible {
    color: $color-maven-brand-secondary-1-70;
  }
}

%scroll-link__child {
  border-bottom: 2px solid $color-maven-brand-secondary-1-30;
  line-height: 21px;
  padding-bottom: 2px;
}

%dark-blue-button {
  background-color: $color-maven-brand-primary-3;
  border-color: darken($color-maven-brand-primary-3, 1%);
  .has-hover &:hover {
    background-color: lighten($color-maven-brand-primary-2, 3%);
    border-color: darken($color-maven-brand-primary-2, 1%);
  }
  &.focus-visible {
    background-color: darken($color-maven-brand-primary-2, 6%);
  }
}

%stacked-form-item {
  margin-bottom: 0;
  padding-bottom: 15px;
  &:last-child {
    padding-bottom: 0;
  }
  // Fix for ant d not optimized for stacked form
  .ant-form-item-label {
    line-height: 1.5;
  }
  // Fix for ant d uneven padding between textarea and input
  .ant-form-item-control {
    textarea {
      margin-top: 4px;
    }
  }
}

%answers-negative-margins {
  margin-left: -10px;
  margin-right: -10px;
  @media screen and (min-width: $min-tablet) {
    margin-left: -18px;
    margin-right: -18px;
  }
}

%admin-container {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
}

%admin-row {
  display: block !important;
  margin-top: 15px !important;
}

// #endregion

// #region Mixins

@mixin full-width-bg($color) {
  position: relative;
  &:before {
    content: "";
    background-color: $color;
    position: absolute;
    height: 100%;
    width: 200vw;
    left: -100vw;
  }
}

@mixin color-button($color: $color-maven-brand-primary-2) {
  background-color: $color;
  border-color: darken($color, 1%);
  .has-hover &:hover {
    background-color: lighten($color, 3%);
    border-color: darken($color, 1%);
  }
  &:active, &:focus {
    background-color: $color;
    border-color: darken($color, 1%);
  }
  &.focus-visible {
    background-color: darken($color, 6%);
  }
}

// #endregion

// #region Mobile Overrides

@media screen and (max-width: $max-phone) {
  div h3.ant-typography {
    font-size: 20px;
  }
  div h4.ant-typography {
    font-size: 16px;
    &.AppSolutions__solution__title {
      font-size: 18px;
    }
  }
}

// #endregion

// #region AntD Overrides

// Remove badly spaced colon on form labels
div.ant-form-item-label > label::after {
  content: '';
}

div.ant-form-item-label {
  padding: 0;
}

// #endregion

// #region Toastify Overrides

.App__ToastContainer.Toastify__toast-container {
  top: 8px;
  .Toastify__toast {
    border-radius: 5px;
  }
  .Toastify__toast-body {
    color: $color-maven-brand-secondary-2-dark;
    padding-left: 10px;
  }
  .Toastify__close-button {
    color: $color-maven-brand-primary-2;
  }
  .Toastify__progress-bar {
    background: $color-maven-brand-primary-2-20;
  }
}

// #endregion

// #region ScrollDownMsg

@keyframes bounce {
  from,
  10%,
  27%,
  50% {
    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0);
  }

  20%,
  21% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -10px, 0) scaleY(1.1);
  }

  35% {
    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);
    transform: translate3d(0, -5px, 0) scaleY(1.05);
  }

  40% {
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
    transform: translate3d(0, 0, 0) scaleY(0.95);
  }

  45% {
    transform: translate3d(0, -2px, 0) scaleY(1.02);
  }
}

  .ScrollDownMsg__Icon {
    animation: 2s bounce 300ms infinite;
    transform-origin: center bottom;
    color: $color-maven-brand-primary-2;
    padding-right: 10px;
  }

// #endregion

// #region App

.App {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
}

.App__Header {
  align-items: center;
  display: flex;
  height: auto;
  justify-content: space-between;
  max-width: $site-width;
  padding: 10px $site-padding-sm;
  width: 100%;
  @media screen and (min-width: $min-tablet) {
    padding: 10px $site-padding-lg;
  }
}

.App__Header__Button {
  padding: 10px;
}

.App__Header__img {
  width: 125px;
  height: auto;
  @media screen and (min-width: 450px) {
    width: 150px;
  }
  @media screen and (min-width: $min-tablet) {
    width: 200px;
  }
  @media screen and (min-width: 900px) {
    width: 250px;
    height: 67px;
  }
}

.ant-tooltip {
  font-size: 14px;
}  

.App__Content {
  max-width: $site-width;
  padding: 0 $site-padding-sm 50px $site-padding-sm;
  width: 100%;
  @media screen and (min-width: $min-tablet) {
    padding: 0 $site-padding-lg 50px $site-padding-lg;
  }
}

// #endregion

// #region AppFooter

.AppFooter {
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  height: auto;
  justify-content: center;
  max-width: $site-width;
  padding: 24px $site-padding-sm;
  @media screen and (min-width: 520px) {
    flex-direction: row;
    justify-content: space-between;
  }
  @media screen and (min-width: $min-tablet) {
    padding: 24px $site-padding-lg;
  }
  width: 100%;
}

.AppFooter__content {
  font-size: 12px;
  padding-top: 3px;
  text-align: center;

  @media screen and (min-width: 400px) {
    font-size: 14px;
  }
  @media screen and (min-width: 520px) {
    text-align: right;
  }
  @media screen and (min-width: 900px) {
    font-size: 16px;
  }
}

.AppFooter__MavenLogo {
  width: 100px;
  height: auto;
  @media screen and (min-width: 400px) {
    width: 125px;
  }
  @media screen and (min-width: 520px) {
    width: 150px;
  }
}

.AppFooter__button {
  font-weight: 500;
  opacity: 0.8;
  transition: opacity 0.3s;
  &:hover {
    opacity: 0.6;
  }
}

// #endregion

// #region AppTopBanner

.AppTopBanner {
  align-items: center;
  background-color: $color-maven-brand-primary-1-light;
  box-shadow: 0 10px 10px -10px rgba(0,0,0,0.2) inset;
  color: #282c2f;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.AppTopBanner__content {
  max-width: $site-width;
  padding: 0 $site-padding-sm;
  width: 100%;
  @media screen and (min-width: $min-tablet) {
    padding: 0 $site-padding-lg;
  }
}

.AppTopBanner__content-inner {
  padding: 15px 0 20px 0;
}

// #endregion

// #region AppContent

.AppContent--error {
  margin-top: 15px;

  @media screen and (min-width: $min-tablet) {
    margin-top: 50px;
  }
}

.AppContent--beta {
  text-align: center;
}

// #endregion

// #region AppQuestion, AppAnswer

@for $i from 1 through 8 {
  .toLeft#{$i}-enter {
    opacity: 0;
    transform: translateX(25px);
  }
  
  .toLeft#{$i}-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: 200ms ease;
  }
  
  .toLeft#{$i}-exit {
    opacity: 0;
    transform: translateX(-25px);
    transition: 200ms ease;
  }
  
  .toLeft#{$i}-enter-active, .toLeft#{$i}-exit {
    transition-delay: ($i - 1) * 75ms;
  }
}

.fromBottom-enter {
  opacity: 0;
  transform: translateY(25px);
}

.fromTop-enter {
  opacity: 0;
  transform: translateY(-25px);
}

.fromTop-enter-active {
  opacity: 1;
  transition: 200ms ease;
  transform: translateY(0);
}

.fromBottom-enter-active {
  @extend .fromTop-enter-active;
  transition-delay: 50ms;
}

.fromBottom-exit, .fromTop-exit {
  opacity: 0;
  transition: 200ms ease;
}

div.AppQuestion { // Override container-fluid
  padding-top: 15px;

  @media screen and (min-width: $min-tablet) {
    padding-top: 50px;
  }
}

.AppQuestion__steps.row {
  display: none;

  @media screen and (min-width: $min-tablet) {
    display: flex;
  }
}

.AppQuestion__spacer {
  height: 15px;
  @media screen and (min-width: $min-tablet) {
    height: 50px;
  }
}

.AppQuestion__prevnext {
  display: flex;
  justify-content: space-between;
  padding-top: 20px;
}

div.AppAnswer {
  display: flex;
  flex-direction: column;
  padding: 0;
  @media screen and (min-width: $min-tablet) {
    padding: 0 8px 20px;
  }
}

.AppAnswer__button {
  align-items: center;
  background-image: linear-gradient(
    150deg,
    lighten($color-maven-brand-primary-2, 12%),
    $color-maven-brand-primary-2
  );
  border-radius: 10px;
  cursor: pointer;
  display: flex;
  flex: 1;
  font-size: 20px;
  justify-content: center;
  margin: 10px;
  min-height: 70px;
  padding: 15px;
  position: relative;
  transition: background 2s;
  z-index: 1;
  &:before {
    border-radius: 10px;
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      150deg,
      lighten($color-maven-brand-primary-1, 20%),
      $color-maven-brand-primary-1
    );
    z-index: -1;
    transition: opacity 0.1s linear;
    opacity: 0;
  }
  .has-hover &:hover:before {
    opacity: 1;
  }
  &.is-active, .has-hover &.is-active {
    background: linear-gradient(
      150deg,
      lighten($color-green, 20%),
      $color-green
    );
    &:before {
      opacity: 0;
    }
  }
  &:active, .has-hover &:active {
    background: linear-gradient(
      150deg,
      lighten($color-green, 15%),
      darken($color-green, 5%)
    );
    &:before {
      opacity: 0;
    }
  }
  &:focus {
    outline: none;
  }
  &.focus-visible, .has-hover &.focus-visible {
    box-shadow: 0 0 0 3px transparentize($color-maven-brand-primary-1, 0.5);
  }
}

.AppAnswer__button__title {
  color: white;
  font-weight: 600;
}

.AppAnswer__submit-modal.ant-modal-confirm-confirm .ant-modal-confirm-body > .anticon {
  color: $color-green !important;
}

.AppAnswer__Popover {
  width: 500px;
  max-width: 90vw;
}

// #endregion

// #region AppQuestionContent

div.AppQuestionContent__preamble {
  margin-top: 15px;
  @media screen and (min-width: $min-tablet) {
    margin-top: 50px;
    // Compensate for margins at the top of each question.
    margin-bottom: -25px;
  }
  sub.m {
    font-weight: 800;
  }

  .AppQuestionContent__preamble-title {
    @extend %rounded-container__title;

    font-size: 20px;
    @media screen and (min-width: $min-tablet) {
      font-size: 28px;
    }
  }
  .AppQuestionContent__preamble-subtitle {
    @extend %rounded-container__subtitle;

    font-size: 16px;
    @media screen and (min-width: $min-tablet) {
      font-size: 22px;
    }
    font-weight: 500;

    sub.m {
      font-weight: 600;
    }
  }
}

div.AppQuestionContent__titles {
  @extend %rounded-container;
  margin-top: 15px;
  @media screen and (min-width: $min-tablet) {
    margin-top: 50px;
  }
  .AppQuestionContent__title {
    @extend %rounded-container__title;
  }
  .AppQuestionContent__subtitle {
    @extend %rounded-container__subtitle;
  }
}

.AppQuestionContent__admin {
  @extend %admin-container;
}

.AppQuestionContent__admin-row {
  @extend %admin-row;
}

// #endregion

// #region AppAnswers

div.AppAnswers {
  @extend %answers-negative-margins;
  padding-top: 30px;
  @media screen and (min-width: $min-tablet) {
    padding-top: 50px;
  }
}

.AppAnswers--categories {
  padding: 22px 0 25px;

  @media screen and (min-width: $min-tablet) {
    padding: 42px 0 25px;
  }
}

// #endregion

// #region AppQuestionCategory

div.AppQuestionCategory__header {
  @extend %rounded-container;
  align-items: center;
  background-image: linear-gradient(
    150deg,
    lighten($color-maven-brand-primary-3, 12%),
    $color-maven-brand-primary-3
  );
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin: 18px 0;
  position: relative;
  z-index: 1;
  &:before {
    border-radius: 10px;
    position: absolute;
    content: "";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      150deg,
      lighten($color-maven-brand-primary-3, 20%),
      $color-maven-brand-primary-3
    );
    z-index: -1;
    transition: opacity 0.1s linear;
    opacity: 0;
  }
  .has-hover &:hover:before {
    opacity: 1;
  }
  &:active, .has-hover &:active {
    background: linear-gradient(
      150deg,
      $color-maven-brand-primary-3,
      darken($color-maven-brand-primary-3, 10%)
    );
    &:before {
      opacity: 0;
    }
  }

  h3.AppQuestionCategory__header-title {
    @extend %rounded-container__title;
    color: white;
  }
}

.AppQuestionCategory__arrow {
  color: white;
  font-size: 16px;
  @media screen and (min-width: $min-tablet) {
    font-size: 22px;
  }
  svg {
    transition: transform 0.3s;
    transform: rotate(0deg);
  }
  &.is-open svg {
    transform: rotate(180deg);
  }
}

div.AppQuestionCategory__answers {
  @extend %answers-negative-margins;
  @media screen and (min-width: $min-tablet) {
    padding-top: 13px;
  }
}

// #endregion

// #region AppAlert

.AppAlert {
  margin: 0;
}

.AppAlert__content {
  padding-top: 5px;
}

.AppAlert__section {
  align-items: center;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.AppAlert__text {
  padding: 5px 10px 5px 0;
}

.AppAlert__text__title {
  font-size: 18px;
  font-weight: 600;
}

.AppAlert__text__sub {
  font-size: 16px;

  sub.m {
    font-weight: 500;
  }
}

.AppAlert__btn-container {
  padding: 4px 0;
}

.AppAlert__Button {
  @include color-button();
}

// #endregion

// #region AppSteps

div.AppSteps {
  .AppSteps__Step {
    display: inline-flex;
    .ant-steps-item-container {
      display: flex;
      flex: 1;
      margin: 0;
      position: relative;
      .ant-steps-item-icon {
        align-items: center;
        background: none;
        border: 0;
        display: flex;
        flex: 1;
        justify-content: center;
        margin: 0;
        .ant-steps-icon {
          align-items: center;
          border-width: 1px;
          border-style: solid;
          border-color: $color-maven-brand-secondary-2;
          border-radius: 24px;
          display: flex;
          height: 24px;
          justify-content: center;
          transition: color 0.3s, border-color 0.3s !important;
          width: 24px;
        }
      }
      .ant-steps-item-content {
        background-color: transparentize($color-maven-brand-secondary-3, 0.05);
        border-radius: 6px;
        opacity: 0;
        min-width: 83px;
        padding: 1px 7px 0;
        position: absolute;
        text-align: center;
        transition: opacity 0.2s ease;
        top: -35px;
        z-index: 1;
        .ant-steps-item-title {
          color: $color-maven-brand-secondary-1;
          font-size: 12px;
        }
        &:after {
          content: "";
          position: absolute;
          top: 100%;
          margin-left: -5px;
          border-width: 5px;
          border-style: solid;
          border-color: transparentize($color-maven-brand-secondary-3, 0.05) transparent transparent transparent;
        }
      }
      &:hover .ant-steps-item-content {
        opacity: 1;
        .ant-steps-item-title {
          color: $color-maven-brand-secondary-1 !important;
        }
      }
    }
  }
}

div.AppSteps {
  .AppSteps__Step.ant-steps-item-wait {
    .ant-steps-item-container {
      .ant-steps-icon {
        border-color:  rgba(0, 0, 0, 0.25);
      }
      &:hover .ant-steps-icon {
        border-color: $color-maven-brand-secondary-2
      }
    }

  }
  .AppSteps__Step.ant-steps-item-process {
    .ant-steps-item-container {
      &:hover .ant-steps-item-content {
        opacity: 0;
      }
      .ant-steps-item-icon {
        .ant-steps-icon {
          background-color: $color-maven-brand-secondary-2;
        }
      }
    }
  }
}

// Tooltip and arrow positioning
div.AppSteps .AppSteps__Step--tooltip-left .ant-steps-item-content {
  left: 0;
  &:after {
    left: 42px;
  }
}

div.AppSteps .AppSteps__Step--tooltip-right .ant-steps-item-content {
  right: 0;
  &:after {
    right: 37px;
  }
}

// #endregion

// #region AppPrevNext__Button

.AppPrevNext__Button {
  margin-top: 15px;
}

// #endregion

// #region AppResult

.AppResult__title-meta {
  color: $color-maven-brand-secondary-1-70;
  flex-shrink: 0;
  font-weight: 600;
  @media screen and (min-width: 375px) {
    text-align: right;
  }
}

.AppResult__result-id, .AppResult__contact {
  cursor: pointer;
  transition: color 0.1s;
  &:hover, &:focus {
    color: $color-maven-brand-secondary-1;
    outline: none;
  }
}

.AppResult__contact {
  text-align: right;
  &:active {
    color: $color-maven-brand-secondary-1-70;
  }
}

.AppResult__pdf {
  bottom: 0;
  display: flex;
  height: auto;
  justify-content: flex-end;
  padding: 10px;
  position: sticky;
  right: 0;
  width: auto;
}

.AppResult__admin {
  @extend %admin-container;
}

.AppResult__admin-row {
  @extend %admin-row;
}

// #endregion

// #region AppResultDetails

.AppResultDetails {
  display: grid;
  grid-template:
    "result-content"
    "share-btn"
    "contact-content"
    "contact-btn";
  grid-gap: 10px;
  @media screen and (min-width: $min-tablet) {
    grid-template:
      "result-content contact-content"
      "share-btn contact-btn" / 1fr 1fr;
    grid-column-gap: 60px;
  }
}

.AppResultDetails_share-btn {
  grid-area: share-btn;
}

.AppResultDetails__result-content {
  grid-area: result-content;
  padding: 5px 0 10px;
  @media screen and (min-width: $min-tablet) {
    padding: 5px 0 10px;
  }
}

.AppResultDetails__contact-content {
  grid-area: contact-content;
  padding: 5px 0;
}

.AppResultDetails__contact-btn {
  grid-area: contact-btn;
}

.AppResultDetails__title {
  font-size: 18px;
  font-weight: 600;
  padding-bottom: 15px;
}

.AppResultDetails__result-id-text {
  padding-bottom: 5px;
}

.AppResultDetails__Button {
  @include color-button();
}

// #endregion

// #region AppDisplayResultHeading

div.AppDisplayResultHeading {
  @extend %rounded-container;
  &:first-child {
    margin-top: 10px;
    @media screen and (min-width: $min-tablet) {
      margin-top: 25px;
    }
  }
  background-color: $color-maven-brand-primary-3;
  .AppDisplayResultHeading__title {
    @extend %rounded-container__title;
    color: $color-maven-brand-secondary-1;
    padding-right: 10px;
  }
  .AppDisplayResultHeading__subtitle {
    @extend %rounded-container__subtitle;
    color: $color-maven-brand-secondary-1;
    margin-bottom: 7px;
  }  
}

.AppDisplayResultHeading__split-container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (min-width: 375px) {
    align-items: center;
    flex-direction: row;
  }
}

// #endregion

// #region AppDisplayResultCard

div.AppDisplayResultCard {
  @extend %rounded-container--result-card;
  &.is-highlighted {
    background-color: $color-maven-brand-primary-2-80;
  }
}

.AppDisplayResultCard__header {
  display: flex;
  justify-content: space-between;
}

.AppDisplayResultCard__title {
  @extend %rounded-container__title--result-card;
  margin: -2px 0;
}

.AppDisplayResultCard__title-text {
  margin: 2px 0;
  padding-right: 15px;

  sub.m {
    font-weight: 700;
  }
}

.AppDisplayResultCard__section {
  padding-top: 15px;
  &:not(.is-below-title):first-child {
    padding-top: 3px;
  }
  p:last-child {
    padding-bottom: 0;
  }
}

.AppDisplayResultCard__header-btn {
  @extend %scroll-link;
}

.AppDisplayResultCard__header-btn__text {
  @extend %scroll-link__child;
}

.AppDisplayResultCard__header-btn__arrow {
  @extend %scroll-link__child;
  display: inline;
  font-size: 14px;
  padding-left: 5px;
  vertical-align: baseline;
  svg {
    position: relative;
    top: 1px;
    transition: transform 0.3s;
    transform: rotate(0deg);
  }
  &.is-open svg {
    transform: rotate(180deg);
  }
}

.AppDisplayResultCard__related-question {
  color: transparentize($color-maven-brand-secondary-1, 0.45);
  font-size: 14px;
  font-style: italic;
}

// #endregion

// #region AppDisplayResultTags

.AppDisplayResultTags {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  font-weight: 400;
  margin: 2px 0;
}

span.AppDisplayResultTags__Tag {
  margin: 4px 8px 4px 0;
}

// #endregion

// #region AppDisplayResultAnswerRecap

div.AppDisplayResultAnswerRecap {
  border: 1px solid $color-maven-brand-primary-2-20;
  border-radius: 10px;
  margin-top: 15px;
  @media screen and (min-width: $min-tablet) {
    margin-top: 25px;
  }
  padding: 8px 24px;
  .ant-list-item-meta {
    align-items: center;
  }
  .AppDisplayResultAnswerRecap__Avatar {
    background-color: $color-maven-brand-primary-2;
  }
}

.AppDisplayResultAnswerRecap__Link {
  display: inline-block;
  .AppDisplayResultAnswerRecap__Link__Icon {
    color: transparentize($text-color-secondary, 0.5);
    transition: color 0.1s;
  }
  .has-hover &:hover .AppDisplayResultAnswerRecap__Link__Icon, &:active {
    color: $text-color;
  }
}

.AppDisplayResultAnswerRecap__Tooltip.ant-tooltip {
  font-size: 14px;
}  

// #endregion

// #region AppDisplayResultCallToAction

div.AppDisplayResultCallToAction {
  @extend %rounded-container--result-card;
}

.AppDisplayResultCallToAction__content {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: -10px 0;
}

.AppDisplayResultCallToAction__title {
  font-size: 16px;
  font-weight: 600;
  padding: 10px 20px 10px 0;
  @media screen and (min-width: $min-tablet) {
    font-size: 22px;
  }
}

.AppDisplayResultCallToAction__btn-container {
  padding: 10px 0;
}

.AppDisplayResultCallToAction__Button {
  @include color-button($color-maven-brand-primary-3);
}

// #endregion

// #region AppCopyInput

.AppCopyInput {
  .ant-input-group-addon {
    padding: 0;
  }
}

.AppCopyInput__Button {
  border: 0;
  height: 100%;
  width: 40px;
}

// #endregion

// #region AppContactModal

.AppContactModal__alert-wrapper {
  padding-top: 25px;
}

.AppContactModal__Form.ant-form {
  padding-top: 15px;
  .AppContactModal__FormItem {
    @extend %stacked-form-item;
  }
}

// #endregion

// #region AppEmailResultModal

.AppEmailResultModal__info {
  font-size: 14px;
  margin-bottom: 15px;
}

.AppEmailResultModal__content {
  height: 410px;
}

.AppEmailResultModal__Form.ant-form {
  padding-top: 0;
  .AppEmailResultModal__FormItem {
    @extend %stacked-form-item;
  }
  .AppEmailResultModal__FormItem--multi {
    .ant-form-item-children {
      @media screen and (min-width: $min-tablet) {
        align-items: center;
        display: flex;
        height: 40px;
      }
    }
    .AppEmailResultModal__first-name-item {
      margin-bottom: 0;
      @media screen and (min-width: $min-tablet) {
        margin-right: 10px;
      }
    }
    .AppEmailResultModal__last-name-item {
      margin-bottom: 0;
      @media screen and (min-width: $min-tablet) {
        flex: 1;
      }
    }
  }
}

.AppEmailResultModal__Spin {
  margin: 30px 0;
  width: 100%;
}

// #endregion

// #region AppEmailShareModal

.AppEmailShareModal__Form.ant-form {
  .AppEmailShareModal__FormItem {
    @extend %stacked-form-item;
  }
  .AppEmailShareModal__FormItem--your-email {
    padding-bottom: 0;
  }
}

// #endregion

// #region AppPDF

button.AppPDF__Button {
  &, &:focus {
    background-color: $color-maven-brand-primary-3-80;
    border: 0;
    i {
      position: relative;
      top: -1px;
      svg {
        width: 19px;
      }
    }
  }
  &:hover {
    background-color: $color-maven-brand-primary-3;
  }
}

.AppPDF__Modal {
  .ant-modal-footer {
    display: flex;
    justify-content: space-between;
  }
  .AppPDF__Modal__footer-left {
    text-align: left;
  }
  .AppPDF__Modal__footer-right {
    text-align: right;
  }
}

.AppPDF__Preview {
  width: 100%;
  height: 400px;
  border: 1px solid $color-maven-brand-secondary-2-60;
}

// #endregion

